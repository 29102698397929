import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import DeliveryAPIError from 'components/contentstack/DeliveryAPIError';
import { appLocales } from 'helpers/constants/appLocales';
import ContentstackRenderer from '../../../../components/contentstack/renderer';
import { getLocalizationInfo } from '../../../../project.config';
import { TLocalizationInfo } from '../../../../types/TLocalizationInfo';
import ErrorPage from 'components/contentstack/ErrorPage';

const ContentstackLNEHomepageTastic = ({ data }: any) => {
  const content = data?.data?.dataSource.payload[0] as any;
  const router = useRouter();
  const localizationInfo: TLocalizationInfo = getLocalizationInfo(router.locale);
  const locale = localizationInfo.appLocale || 'en_US';

  if (!content || [appLocales.PT_BR].includes(locale)) {
    return <ErrorPage />;
  } else if (content?.err) {
    return <DeliveryAPIError data={content.err} />;
  } else {
    return (
      <>
        <Head>
          <title>{content.data.title}</title>
          {content.data.meta_data?.meta_description && (
            <meta name="description" content={content.data.meta_data?.meta_description} />
          )}
          {content.data.meta_data?.meta_keywords && (
            <meta name="keywords" content={content.data.meta_data?.meta_keywords} />
          )}
        </Head>

        <ContentstackRenderer data={content.data} contentTypeUid={content.contentTypeUid} />
      </>
    );
  }
};

export default ContentstackLNEHomepageTastic;
